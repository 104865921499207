const seo = {
  '/404': {
    title: '404 Not found',
  },
  '/about': {
    title: 'About',
  },
  '/contact': {
    title: 'Contact',
  },
};

export default seo;
