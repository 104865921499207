module.exports = [{
      plugin: require('E:/Developer/JANUARY PROJECT 2024/www.usurppower.com-master/src/www.usurppower.com-master/node_modules/gatsby-plugin-layout/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('E:/Developer/JANUARY PROJECT 2024/www.usurppower.com-master/src/www.usurppower.com-master/node_modules/gatsby-plugin-mdx/gatsby-browser.js'),
      options: {"plugins":[],"defaultLayouts":{"default":"E:\\Developer\\JANUARY PROJECT 2024\\www.usurppower.com-master\\src\\www.usurppower.com-master\\src\\layouts\\index.js"},"extensions":[".mdx",".md"]},
    },{
      plugin: require('E:/Developer/JANUARY PROJECT 2024/www.usurppower.com-master/src/www.usurppower.com-master/node_modules/gatsby-remark-images/gatsby-browser.js'),
      options: {"plugins":[],"maxWidth":900},
    },{
      plugin: require('E:/Developer/JANUARY PROJECT 2024/www.usurppower.com-master/src/www.usurppower.com-master/node_modules/gatsby-plugin-google-analytics/gatsby-browser.js'),
      options: {"plugins":[],"trackingId":"UA-118091102-1"},
    },{
      plugin: require('E:/Developer/JANUARY PROJECT 2024/www.usurppower.com-master/src/www.usurppower.com-master/node_modules/gatsby-plugin-offline/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('E:/Developer/JANUARY PROJECT 2024/www.usurppower.com-master/src/www.usurppower.com-master/gatsby-browser.js'),
      options: {"plugins":[]},
    }]
